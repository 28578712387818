import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import RWBUserImages from '../RWBUserImages';
import styles from './ProfileDetail.module.css';
import {IconButton, Grid} from '@material-ui/core';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {userProfile} from '../../../../shared/models/UserProfile';
import {rwbApi} from '../../../../shared/apis/api';
import Loading from '../Loading';
import RWBMark from '../svgs/RWBMark';
import ChevronBackIcon from '../svgs/ChevronBackIcon';
import {ClipLoader} from 'react-spinners';
import FeedList from '../feed/FeedList';
import ExpandingText from '../ExpandingText';
import {
  logUnfollow,
  logFollow,
  FOLLOW_LOCATION,
  webSectionName,
  logViewFollowRelationship,
} from '../../../../shared/models/Analytics';
import {hasReachedBottom} from '../../BrowserUtil';
import ChallengeBadges from './ChallengeBadges';
import BlockAndReportOverlay from '../BlockAndReportOverlay';
import {displayMilitaryInfo, isAdminOrModeratorOfGroup} from '../../../../shared/utils/Helpers';
import ReusableTabs from '../ReusableTabs';
import { MY_PROFILE_TABS, PROFILE_TAB, PROFILE_TAB_ADMIN } from '../../../../shared/constants/ProflieTabs';
import { MANAGING_PROFILE_AND_SETTINGS } from '../../../../shared/constants/newAnalytics';
import MemberInfo from './MemberInfo';
import { PROFILE_TAB_LABELS } from '../../../../shared/constants/Labels';
import { insertLocaleSeperator } from '../../../../shared/utils/ChallengeHelpers';
import ChevronRightIcon from '../svgs/ChevronRightIcon';
import FollowButton from '../FollowButton';
import EagleLeaderInfo from '../../../../shared/models/EagleLeaderInfo';

export default function ProfileDetail() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false); // server code to see if the person is being followed
  const [isFollowed, setIsFollowed] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [chapter, setChapter] = useState('');
  // const [title, setTitle] = useState('');
  const [militaryBranch, setMilitaryBranch] = useState('');
  const [militaryRank, setMilitaryRank] = useState('');
  // const [militarySpecialty, setMilitarySpecialty] = useState('');
  const [militaryStatus, setMilitaryStatus] = useState('');
  const [profileBio, setProfileBio] = useState('');
  const [coverPhoto, setCoverPhoto] = useState('');
  const [profilePhoto, setProfilePhoto] = useState('');
  const [profileLocation, setprofileLocation] = useState('');
  const [followingAmount, setFollowingAmount] = useState('-');
  const [followersAmount, setFollowersAmount] = useState('-');
  const [expirationTermOfService, setExpirationTermOfService] = useState(null);
  const [anonymousProfile, setAnonymousProfile] = useState(false);
  const [isLoadingFeed, setIsLoadingFeed] = useState(true);
  const [userFeed, setUserFeed] = useState([]);
  const [retrievedLastPost, setRetrievedLastPost] = useState(false);
  const [userBadges, setUserBadges] = useState([]);
  const [activeTab, setActiveTab] = useState(location?.state?.activeTab || 0); //change to 0 for default, keeping as 2 for testing health tabs
  const [userMetrics, setUserMetrics] = useState({});
  const [isCurrentUserAdmin, setIsCurrentUserAdmin] = useState(new Set());
  const [isMetricsLoading, setIsMetricsLoading] = useState(true);
  const [user, setUser] = useState('')

  const loggedInUser = userProfile.getUserProfile();
  const {profileId} = useParams();
  const history = useHistory();
  const match = useRouteMatch();
  let eagleLeaderInfo = new EagleLeaderInfo(user.eagle_leader_info);
  let currentEagleLeaderInfo = new EagleLeaderInfo(userProfile.getUserProfile().eagle_leader_info)
  useEffect(() => {
    setIsLoading(true);
    retrieveUser()
      .then((user) => {
        setUser(user)
        setprofileLocation(user.location);
        setCoverPhoto(user.cover_photo_url);
        setProfilePhoto(user.profile_photo_url);
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setChapter(user?.preferred_chapter?.name);
        setMilitaryBranch(user.military_branch);
        setMilitaryStatus(user.military_status);
        setExpirationTermOfService(user.military_ets);
        setMilitaryRank(user.military_rank);
        setProfileBio(user.profile_bio);
        setAnonymousProfile(user.anonymous_profile);
        getUsersFollowSummary().then((fData) => {
          setFollowingAmount(fData.following);
          setFollowersAmount(fData.followers);
          getFollowerRelationship().then((followed) => {
            setIsFollowed(followed);
            getFollowingRelationship().then((following) => {
              setIsFollowing(following);
              setIsLoading(false);
            });
          });
        });
        getBadges();
        if (user.anonymous_profile) {
          alert('Team RWB: This user has set their profile to private.');
          history.goBack();
        }
        else {
          getFeed()
            .then((feed) => {
              setUserFeed(feed);
              setIsLoadingFeed(false);
            })
            .catch(() => {
              setIsLoadingFeed(false);
              alert('Error retrieving the user feed');
            });
        }
      })
      .catch((error) => {
        console.warn(error);
        setIsLoading(false);
        setIsLoadingFeed(false);
        if (error.toString().includes('403')) {
          alert('This user has set their profile to private.');
          history.goBack();
        } else alert('There was an error retrieving the user.');
      });
  }, []);

  useEffect(() => {
    // ensure we only track scrolling to load more posts when on the feed tab
    if (activeTab === 0) {
      window.addEventListener('scroll', trackScrolling);
      return () => {
        window.removeEventListener('scroll', trackScrolling);
      };
    }
  }, [userFeed, activeTab]);

  useEffect(() => {
    // replacement for setState callback
    getWorkoutMetrics();
    getAllGroups();
    if (retrievedLastPost) handleLoadMore();
  }, [retrievedLastPost]);

  const trackScrolling = (event) => {
    event.preventDefault();
    const wrappedElement = document.getElementById('root');
    if (hasReachedBottom(wrappedElement)) {
      setRetrievedLastPost(true);
    }
  };

  const getUsersFollowSummary = () => {
    return rwbApi.getFollowSummary(profileId);
  };

  // see if this profile is following the logged in user
  const getFollowerRelationship = () => {
    return rwbApi
      .getFollowingRelationships(profileId, loggedInUser.id)
      .then((isFollowed, isFollowing) => {
        return isFollowed;
      });
  };

  const getAllGroups = () => {
    rwbApi.getAllGroupMembership(profileId)
    .then((result) => {
      const anotherUserGroupIds = new Set(result.map(item => item.group_id));
      const isMatch = isAdminOrModeratorOfGroup(userProfile.getUserProfile().groupsJoined, anotherUserGroupIds);
      setIsCurrentUserAdmin(isMatch);
    })
    .catch((error) => {
      console.error('Error fetching bulk following:', error);
    });
  }

  // see if the logged in user is following this profile
  const getFollowingRelationship = () => {
    return rwbApi
      .getFollowingRelationships(loggedInUser.id, profileId)
      .then((isFollowing) => {
        return isFollowing;
      });
  };

  const retrieveUser = () => {
    return rwbApi.getUserByID(profileId);
  };

  const getFeed = () => {
    return rwbApi
      .getUserFeed(profileId)
      .then((result) => {
        if (result.data) {
          // ensures editing posts on other users' profiles is updated
          setUserFeed(result.data.results);
          return result.data.results;
        }
        return [];
      })
      .catch((error) => {
        console.warn('error getting feed: ', error);
      });
  };

  const getBadges = () => {
    return rwbApi
      .getUserBadges(profileId)
      .then((result) => {
        setUserBadges(result.data.badges);
      })
      .catch((error) => {
        console.warn(error);
        alert("Error retrieving user's badges.");
      });
  };

  const getWorkoutMetrics = () => {
    return rwbApi
      .getUserMetrics(profileId)
      .then((result) => {
        setUserMetrics(result.data);
        setIsMetricsLoading(false);
      })
      .catch((error) => {
        console.warn(error);
        alert("Error retrieving user's metrics.");
        setIsMetricsLoading(false);
      });
  };

  const handleLoadMore = () => {
    const lastPost = userFeed[userFeed.length - 1];
    setIsLoadingFeed(true);
    if (retrievedLastPost && lastPost) {
      rwbApi.getUserFeed(profileId, lastPost.id).then((result) => {
        if (result.data.results.length > 0) {
          setUserFeed([...userFeed, ...result.data.results]);
          setIsLoadingFeed(false);
          setRetrievedLastPost(false);
        } else setIsLoadingFeed(false);
      });
    }
  };

  return (
    <div id={'root'}>
      {isLoading && (
        <Loading size={100} color={'var(--white)'} loading={true} right />
      )}
      <div className={styles.userImageContainer}>
        <RWBUserImages coverPhoto={coverPhoto} profilePhoto={profilePhoto} />

        <div className={styles.navigation}>
          <div onClick={() => history.goBack()}>
            <IconButton edge="start" color="inherit">
              <ChevronBackIcon tintColor={'var(--magenta)'} />
            </IconButton>
          </div>
          {profileId !== parseInt(loggedInUser.id) ? (
            <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
              <BlockAndReportOverlay
                loggedInUser={loggedInUser}
                profileId={profileId}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.contentContainer}>
          <div className={styles.nameContainer}>
            <h1>{`${firstName} ${lastName} `}</h1>
            {eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE && (
              <RWBMark tintColor={'var(--navy)'} width={40} height={18} />
            )}
          </div>
          <div className={styles.nameContainer}>
            <h2>{chapter}</h2>
          </div>
          <div className={styles.nameContainer}>
            {militaryStatus ? (
              <p>{displayMilitaryInfo(militaryStatus, militaryBranch)}</p>
            ) : null}
          </div>
          <div className={styles.nameContainer}>
            {eagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE && (
              <h5 className={styles.eagleLeaderText}>{eagleLeaderInfo.role}</h5>
            )}
          </div>
        
        

        <div className={styles.profileActions}>
          <div
            className={styles.followCount}
            onClick={() => {
              logViewFollowRelationship({
                section_name: webSectionName(),
                click_text: 'followers',
              });
              history.push({
                pathname: `${match.path}/followers`,
                state: {index: 0, selectedUserID: profileId},
              });
            }}>
            <strong>{followersAmount}</strong>
            followers
          </div>
          <div
            className={styles.followCount}
            onClick={() => {
              logViewFollowRelationship({
                section_name: webSectionName(),
                click_text: 'following',
              });
              history.push({
                pathname: `${match.path}/followers`,
                state: {index: 1, selectedUserID: profileId},
              });
            }}>
            <strong>{followingAmount}</strong>
            following
          </div>
        </div>
        {isFollowed && (
          <h5 className={styles.followsYouText}> follows you</h5>
        )}
        <div style={{display: 'flex',justifyContent: 'center'}}>
        {!anonymousProfile && loggedInUser.id !== parseInt(profileId) && (
            <FollowButton
              user={user}
              followersAmount={followersAmount}
              setFollowersAmount={setFollowersAmount}
            />
          )}
        </div>

        {/* {militaryStatus === 'Veteran' && (
          <p>
            ETS:{' '}
            {expirationTermOfService
              ? moment(expirationTermOfService).format('Y')
              : 'N/A'}
          </p>
        )} */}
        {/* {militaryStatus !== 'Civilian' && militaryRank && (
          <p>Rank: {militaryRank}</p>
        )} */}
        <br />
      </div>
      <ReusableTabs
            selectedValue={activeTab}
            values={(isCurrentUserAdmin && (currentEagleLeaderInfo.status === EagleLeaderInfo.STATUS.ACTIVE) ? PROFILE_TAB_ADMIN : PROFILE_TAB).map(({ name }) => name)}
            onChangeHandler={(key) => {
              if (activeTab !== key) {
                history.replace({ ...location, state: {activeTab: key} });
                setActiveTab(key)
              }
            }}
          />
      {activeTab === 0 && !anonymousProfile // feed and user summary
        ? 
        <>
          <div className={styles.contentContainer}>
          <p>
            {profileBio}
          </p>
          </div>
          <FeedList
            userFeed={userFeed}
            origin={'user'}
            mergeNewPost={getFeed}
          />
          <Grid container justify={'center'}>
            <ClipLoader size={60} color={'var(--grey20)'} loading={isLoadingFeed} />
          </Grid>
        </>
        : 
        activeTab === 1 && !anonymousProfile ?
        <div className={styles.contentContainer}>
          {/* Badges */}
          {userBadges.length ?
          <>
          <ChallengeBadges userBadges={userBadges} myProfile={true} />
          <br />
          <div className={styles.badgeStatLink}>
            <h3
              onClick={() => {
                history.push({
                  pathname: `${profileId}/workout-log`,
                  state: { userMetrics },
                });
              }}
              className={styles.healthLink}>
              {PROFILE_TAB_LABELS.EVENT_AND_WORKOUT_STATS}
            </h3>
            <ChevronRightIcon />
          </div>
          </> : null}
          {userMetrics.all_time_challenges_participated !== undefined &&
          userMetrics.ytd_events_participated !== undefined &&
          userMetrics.mtd_events_participated !== undefined ?
          <div className={styles.workoutContainer}>
           <div>
              <h1 className={styles.stat}>
                {insertLocaleSeperator(
                  parseInt(userMetrics.all_time_challenges_participated),
                )}
              </h1>
              <p className={styles.statName}>
                {PROFILE_TAB_LABELS.ALL_TIME_CHALLENGES}
              </p>
            </div>
            <div>
              <h1 className={styles.stat}>
                {insertLocaleSeperator(
                  parseInt(userMetrics.ytd_events_participated),
                )}
              </h1>
              <p className={styles.statName}>
                {PROFILE_TAB_LABELS.YTD_EVENTS}
              </p>
            </div>
            <div>
              <h1 className={styles.stat}>
                {insertLocaleSeperator(
                  parseInt(userMetrics.mtd_events_participated),
                )}
              </h1>
              <p className={styles.statName}>
                {PROFILE_TAB_LABELS.MTD_EVENTS}
              </p>
            </div>
          </div> : <Loading
                size={30}
                color={'var(--grey40)'}
                loading={true}
                footerLoading
            />}
        </div>
        :
        activeTab === 2 && !anonymousProfile ?
        <MemberInfo user={user}/>
        : null
      }
      {!anonymousProfile && <Grid container justify={'center'}>
        <ClipLoader size={60} color={'var(--grey20)'} loading={isLoadingFeed} />
      </Grid>}
    </div>
  );
}
