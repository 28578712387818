export function separatePunctuation(words, validPunctuation) {
  if (!words) return words; // edge case of no words
  let updatedWords = [];
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (validPunctuation.includes(word.charAt(word.length - 1))) {
      // add the word without the punctuation
      updatedWords.push(word.slice(0, word.length - 1));
      // add the punctuation
      updatedWords.push(word.slice(word.length - 1));
    }
    else {
      updatedWords.push(word);
    }
  }
  return updatedWords;
}

export function formatEventCardTitle(eventTitle) {
  if (eventTitle) {
    if (eventTitle.length > 30) return `${eventTitle.substring(0, 27)}...`.toUpperCase();
    else return eventTitle.toUpperCase();
  }
  else return 'INVALID EVENT';
}

  // filter out the pinned post that would show up in the feed
export function isPostVisible(pinnedPost, item) {
    if (pinnedPost === undefined || pinnedPost === []) return true;
    return (
      item.activities[0].id !== pinnedPost[0].activities[0].id ||
      (item.activities[0].id === pinnedPost[0].activities[0].id &&
        item.activities[0].is_pinned)
    );
  };

export const lastCharSymbolRegex = /[.,\/#!$%\^&\*;:{}=\-_`~()]$/;

export function getFormattedPostElements(postText, expectedNames, namesAreLinks, renderUsernameFn, links, renderLinkFn, nonUserTags, renderNonUserTagsFn) {
  if (!expectedNames && !links && !nonUserTags) return postText; // Return early if there are no names or links
  const elements = [];
  let lastIdx = 0;

  // Create a sorted list of all tokens (links and tags)
  const tokens = [];
  if (links) {
      links.forEach(link => {
          let index = postText.indexOf(link);
          while (index >= 0) {
              tokens.push({ type: 'link', value: link, index });
              index = postText.indexOf(link, index + link.length);
          }
      });
  }

  if (expectedNames) {
      expectedNames.forEach(name => {
          const taggedName = `@${name}`;
          let index = postText.indexOf(taggedName);
          while (index >= 0) {
              tokens.push({ type: 'tag', value: name, index });
              index = postText.indexOf(taggedName, index + taggedName.length);
          }
      });
  }

  if (nonUserTags) {
    nonUserTags.forEach(tag => {
      const nonUserTag = `@${tag}`;
      let index = postText.indexOf(nonUserTag);
      while (index >= 0) {
        tokens.push({type: 'nonUserTag', value: tag, index});
        index = postText.indexOf(nonUserTag, index + nonUserTag.length);
      }
    })
  }

  // Sort tokens by index
  tokens.sort((a, b) => a.index - b.index);
  // Build the final elements array
  tokens.forEach((token, i) => {
      // Add text before the token
      if (token.index > lastIdx) {
          elements.push(postText.substring(lastIdx, token.index));
      }

      // Add the token
      if (token.type === 'link') {
          elements.push(renderLinkFn(token.value));
      } else if (token.type === 'tag') {
          elements.push(renderUsernameFn(token.value, token.index, namesAreLinks));
      } else if (token.type === 'nonUserTag') {
        elements.push(renderNonUserTagsFn(token.value, token.index))
      }

      // Update last index
      lastIdx = token.index + (token.type === 'link' ? token.value.length : `@${token.value}`.length);
  });

  // Add any remaining text after the last token
  if (lastIdx < postText.length) {
      elements.push(postText.substring(lastIdx));
  }

  return elements;
}
